<template>
  <div class="honeycomb d-flex flex-column justify-space-around">
    <section>
      <v-row class="justify-center align-center">
        <v-col class="image__title d-flex justify-center align-start pt-8">
          <img width="40%" src="../assets/conteudos_cover_01.png" alt="" />
        </v-col>
      </v-row>
    </section>
    <div class="container d-flex flex-column pt-10">
      <section class="d-flex justify-center align-center flex-column">
        <v-row class="pos-relative image__links d-flex align-center justify-center">
          <img @click="flyToPage('conteudos_tematicos_crianca_adolescente')" src="../assets/conteudos_cover_02.png" alt="" />
          <img @click="flyToPage('conteudos_tematicos_casas')" src="../assets/conteudos_cover_03.png" alt="" />
          <img @click="flyToPage('conteudos_tematicos_religiosidade')" src="../assets/conteudos_cover_04.png" alt="" />
        </v-row>
        <v-row class="pos-relative negative-top image__links d-flex align-center justify-center">
          <img @click="flyToPage('conteudos_tematicos_genero')" src="../assets/conteudos_cover_05.png" alt="" />
          <img @click="flyToPage('conteudos_tematicos_aguas')" src="../assets/conteudos_cover_06.png" alt="" />
          <img @click="flyToPage('conteudos_tematicos_violacoes_direitos')" src="../assets/conteudos_cover_07.png" alt="" />
        </v-row>
        <v-row class="pos-relative negative-top-lc image__links d-flex align-center justify-center">
          <img @click="flyToPage('conteudos_tematicos_cultura_alimentar')" src="../assets/conteudos_cover_08.png" alt="" />
          <img @click="flyToPage('conteudos_tematicos_ser_atingido')" src="../assets/conteudos_cover_09.png" alt="" />
          <img @click="flyToPage('conteudos_tematicos_luta_continua')" src="../assets/conteudos_cover_10.png" alt="" />
        </v-row>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      active: "active",
    };
  },
  methods: {
    flyToPage(path) {
      this.$router.push({ name: path})
    }
  }
};
</script>

<style scoped>

.pos-relative {
  position: relative;
}

.pos-absolute {
  position: absolute;
}

.negative-top {
  top: -6%;
  left: -7%;
}

.negative-top-lc {
  top: -12%;
}
.honeycomb {
  height: 100%;
  width: 100%;
  background-image: url("/backgrounds/page_conteudos_tematicos.png");
  background-size: cover;
  display: flex;
}

.image__links img {
  width: 15%;
  cursor: pointer;
}

.image__title img {
  max-width: 30%;
}

@media (max-width: 600px) {
  .image__links img {
    width: 25%;
  }
}

@media (max-width: 400px) {
  .image__links img {
    width: 30%;
  }

  .image__title img {
    max-width: 100%;
  }
}
</style>
